<template lang="pug">
div.logout
  vue-headful(title="Ownmesh logout")
</template>

<script>
import CONF from '@/conf'

export default {
  name: 'Logout',
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
    console.log('Logout page creating')
    //        console.log('Cookie session :' + this.$cookie.get('sessionid'))
    let request = this.$http.get(CONF.LOGOUT_URL)
    // let router = this.$router
    request.success = () => {
      console.log('Logout success, redirecting to login page')
      setTimeout(function () { window.location.assign('/') }, 500)
      this.$cookie.delete(CONF.SESSION_COOKIE)
      this.$socket.disconnect()
    }
    request.failed = () => {
      this.message = 'Logout Failed'
    }
    request.then(
      unused_response => { request.success() },
      unused_response => { request.failed() }
    )
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
